<template>
  <div>
    <v-container class="" v-if="!verifyLoading">
      <LogoHeader></LogoHeader>
      <v-row class="justify-center d-flex mt-2">
        <v-col lg="4" md="7" class="mx-auto">
          <v-card class="card-shadow border-radius-xl">
            <div class="d-flex">
              <v-img
                src="@/assets/img/verify_email.png"
                class="mt-2"
                width="100px"
                height="100px"
                :contain="true"
              ></v-img>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <h5 class="text-h5 text-typo font-weight-bold">
                <v-icon
                  size="27px"
                  :style="'transform: translateY(-2px); color: red;'"
                  >fas fa-times-circle</v-icon
                >
                Link niepoprawny
              </h5>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <Footer></Footer>
    </v-container>
    <v-overlay id="verifyOverlay" :opacity="1" :value="verifyLoading">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
        <span :style="'color: white;'">Weryfikuję...</span>
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import qs from "qs";
import LogoHeader from "@/components/util/LogoHeader.vue"
import Footer from "@/components/Footer.vue";
export default {
  name: "VerifyEmailToken",
  data() {
    return {
      verifyLoading: true,
    };
  },
  components:{
    LogoHeader,
    Footer
  },
  methods: {
    setLoading: function (val) {
      this.verifyLoading = val;
    },
  },
  created() {
    if (!this.$route.params.token) {
      return;
    }
    this.$axios({
      method: "post",
      url: "/api/auth/verifyemail",
      data: qs.stringify({
        token: this.$route.params.token,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then(async (response) => {
        if (response.data.success) {
          await this.$store.dispatch("fetchUser");
          this.$router.push("/dashboard");
        } else {
          this.setLoading(false);
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  },
};
</script>

<style>

</style>